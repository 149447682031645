import React from "react";
import { Col, Row, Typography } from "antd";

import { useTranslation } from "react-i18next";

import { WEBINARS } from "../constants";
import { VideoPlayer } from "../components";

const Webinar = () => {
  const { Title } = Typography;
  const { t } = useTranslation();

  return (
    <div className="container">
      <Row justify="center">
        <Col span={20}>
          <Title level={2} style={{ textAlign: "center" }} className="title-blue">
            {t("label_webinar").toUpperCase()}
          </Title>
          <Row justify="center">
            {WEBINARS.map((w, i) => {
              console.log("Webinar.render.map", w);
              return (
                <Col
                  xl={{ span: 16 }}
                  xs={{ span: 24 }}
                  style={{ borderRadius: '16px', backgroundColor: '#FFF', margin: '10px' }}
                  key={w.id}
                >
                  <div key={i} style={{ margin: '10px' }}>
                    <Title level={3} className="title-blue" style={{ textAlign: 'center' }}>{w.title}</Title>
                    <Row style={{ padding: '0px 20px 20px 20px' }}>
                      <Col xl={{ span: 8 }}>
                        <VideoPlayer playback={w.playback} poster={w.poster} width="100%" />
                      </Col>
                      <Col xl={{ span: 15, offset: 1 }}>
                        <p style={{ fontSize: '18px' }}>{w.text}</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Webinar;
