import React from "react";
import { Spin, Typography } from "antd";

const { Title } = Typography;

const Loading = () => {
  return (
    <Typography style={{ textAlign: "center" }}>
      <Title level={3}>
        LOADING...
        <Spin size="large" style={{ padding: "2rem" }} />
      </Title>
    </Typography>
  );
};

export default Loading;
