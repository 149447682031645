import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "antd";

import img404 from "../images/404.png";

const PageNotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { Title } = Typography;

  return (
    <div className="container-image-responsive">
      <Typography style={{ textAlign: "center" }}>
        <img
          src={img404}
          alt={t("404")}
          className="image-responsive"
          style={{ maxWidth: "500px" }}
        />
        <Title level={3}>{t("404").toUpperCase()}</Title>
        <Button shape="round" type="primary" onClick={() => history.push("/")}>
          HOME PAGE
        </Button>
      </Typography>
    </div>
  );
};

export default PageNotFound;
