import React from "react";
import { useTranslation } from "react-i18next";
import mock from "../images/mock.png"
import appstore from "../images/appstore.png";
import googleplay from "../images/googleplay.png"
import { LINKS } from "../constants";
import { Col, Row, } from "antd";

const Download = () => {
  const { t } = useTranslation();

  return (
    <div className="container" style={{ padding: '100px 0px' }}>
      <Row justify="center" className="title-blue message" style={{ borderRadius: '0px', backgroundColor: '#ffffff70' }}>
        <Col xxl={{ span: 8 }} xl={{ span: 10 }} md={{ span: 24 }} xs={{ span: 24 }}
          style={{
            height: '450px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={mock} alt="mock" className="img_mock" />
        </Col>
        <Col xxl={{ span: 8 }} xl={{ span: 10 }} md={{ span: 24 }} xs={{ span: 24 }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="container-img">
            <p
              style={{
                fontSize: "32px",
                lineHeight: "1",
                marginBottom: "0px",
                textAlign: "center",
                maxWidth: '500px',
                fontWeight: '700'
              }}
            >
              {t("label_welcome_education").toUpperCase()}
            </p>

          </div>

          <div className="container-p-welcome">
            <p style={{ fontSize: "26px", fontWeight: "500" }}
              dangerouslySetInnerHTML={{
                __html: t("message_download_app", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></p>
          </div>

          <Row justify="center">
            <Col md={{ span: 9 }} xs={{ span: 14 }}>
              <a href={LINKS.ios} target="_blank" rel="noreferrer">
                <img src={appstore} alt="appstore" width="80%" />
              </a>
            </Col>
            <Col md={{ span: 9 }} xs={{ span: 14 }}>
              <a href={LINKS.android} target="_blank" rel="noreferrer">
                <img src={googleplay} alt="googleplay" width="80%" />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Download;
