import React from "react";
import Hls from "hls.js";

const VideoPlayer = ({ playback, poster }) => {
  const videoRef = React.useRef(null);

  React.useEffect(() => {
    let hls;
    if (videoRef.current) {
      const video = videoRef.current;

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        video.src = playback;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls();
        hls.loadSource(playback);
        hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef, playback]);

  return <video controls ref={videoRef} style={{ width: "100%" }} />;
};
export default VideoPlayer;
