import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import { I18nextProvider } from "react-i18next";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { AuthProvider } from "./providers/AuthProvider";

import i18n from "./i18n";
import { Loading } from "./pages";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

const gtmParams = { id: process.env.REACT_APP_GTM_ID };

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <I18nextProvider i18n={i18n}>
        <GTMProvider state={gtmParams}>
          <AuthProvider>
            <Router>
              <App />
            </Router>
          </AuthProvider>
        </GTMProvider>
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
