import React from "react";
import { Button } from "antd";

const BaseButton = ({ children, ...props }) => {
  return (
    <Button className="shadow-button" {...props}>
      {children}
    </Button>
  );
};
export default BaseButton;
