import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Typography } from "antd";
import Login from "../images/t1.png";
import Test from "../images/t2.png";
import Progress from "../images/t3.png";
import Studying from "../images/t4.png";


const CarouselSlide = () => {
    const { t } = useTranslation();
    const { Title } = Typography;

    const settings = {
        className: "center",
        infinite: true,
        centerPadding: "40px",
        slidesToShow: 1,
        speed: 500,
        dots: true,
        arrows: false,
    };

    return (
        <div className="width_carousel">
            <Slider {...settings} className="dot-white">
                <div>
                    <img src={Studying} alt="studying" width="60%" />
                    <Title level={3} style={{ margin: '20px 20px 50px', color: 'white', fontSize: '34px', width: '70%' }}>
                        {t('label_improve_result').toUpperCase()}
                    </Title>
                </div>
                <div >
                    <img src={Login} alt="studying" width="60%" />
                    <Title level={3} style={{ margin: '20px 20px 50px', color: 'white', fontSize: '34px', width: '70%' }}>
                        {t('label_signup_app').toUpperCase()}
                    </Title>
                </div>
                <div>
                    <img src={Test} alt="studying" width="60%" />
                    <Title level={3} style={{ margin: '20px 20px 50px', color: 'white', fontSize: '34px', width: '70%' }}>
                        {t('label_test_profile').toUpperCase()}
                    </Title>
                </div>
                <div>
                    <img src={Progress} alt="studying" width="60%" />
                    <Title level={3} style={{ margin: '20px 20px 50px', color: 'white', fontSize: '34px', width: '70%' }}>
                        {t('label_progress').toUpperCase()}
                    </Title>
                </div>
            </Slider>
        </div>
    )
}
export default CarouselSlide;