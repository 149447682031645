import React from "react";
import { Col, Form, Input, notification, Row } from "antd";

import { useTranslation } from "react-i18next";
import { useAuthState } from "../providers/AuthProvider";

import { BaseButton } from "../components";

const NewPassword = () => {
  const { t } = useTranslation();
  const { changePassword } = useAuthState();



  const handleSubmit = async ({
    currentPassword,
    password,
    confirmPassword,
  }) => {
    await changePassword(
      currentPassword,
      password,
      confirmPassword,
      onSuccess,
      onError
    );
  };

  const onSuccess = () =>
    notification.success({
      key: "newPassword",
      message: t("message_title_success"),
      description: t('label_change_password_success')
    });

  const onError = (err) =>
    notification.error({
      key: "newPassword",
      message: t("error_notification_title"),
      description: t('label_change_password_error')
    });
    
  return (
      <Row justify="center" >
        <Col span={20}>
          <Form
            size="large"
            layout="vertical"
            onFinish={handleSubmit}
            style={{ padding: "30px 0px 0px" }}
          >
            <Row gutter={16}>
              <Col xl={{ span: 12, offset: 6 }} xs={{ span: 24 }}>
                <p className="paragraph_form">
                  <strong> {t("label_current_password")} </strong>
                </p>
                <Form.Item
                  type="password"
                  name="currentPassword"
                  rules={[
                    { required: true, message: t("message_current_password") },
                  ]}
                >
                  <Input.Password placeholder={t("label_current_password")} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xl={{ span: 12, offset: 6 }} xs={{ span: 24 }}>
                <p className="paragraph_form">
                  <strong> {t("label_new_password")} </strong>
                </p>
                <Form.Item
                  type="password"
                  name="password"
                  rules={[
                    { required: true, message: t("message_form_password") },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const pwdRegex =
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/gm;
                        if (pwdRegex.exec(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(t("message_invalid_password"));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder={t("label_new_password")} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xl={{ span: 12, offset: 6 }} xs={{ span: 24 }}>
                <p className="paragraph_form">
                  <strong>{t("label_repeat_password")}</strong>
                </p>
                <Form.Item
                  type="password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    { required: true, message: t("message_confirm_password") },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          t("message_invalid_confirm_password")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder={t("label_repeat_password")} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col xl={{ span: 4 }} xs={{ span: 10 }}>
                <BaseButton
                  className="shadow-button"
                  type="primary"
                  block
                  shape="round"
                  htmlType="submit"
                >
                  {t("label_save").toUpperCase()}
                </BaseButton>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
  );
};
export default NewPassword;
