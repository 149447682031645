import React from "react";
import { Col, Form, Input, notification, Row } from "antd";

import { useTranslation } from "react-i18next";
import { useAuthState } from "../providers/AuthProvider";

import { BaseButton } from "../components";

const ChangeEmail = () => {
  const { t } = useTranslation();
  const { changeEmail } = useAuthState();

  const handleSubmit = async ({ newEmail, confirmEmail, password }) => {
    await changeEmail(newEmail, confirmEmail, password, onSuccess, onError);
  };

  const onSuccess = () =>
    notification.success({
      key: "newPassword",
      message: t("message_title_success"),
      description: t('label_change_email_success')
    });
    
  const onError = (err) =>
    notification.error({
      key: "newPassword",
      message: t("error_notification_title"),
      description: t('label_change_email_error')
    });

  return (
      <Row justify="center">
        <Col span={20}>
          <Form
            size="large"
            layout="vertical"
            style={{ padding: "30px 0px 0px" }}
            onFinish={handleSubmit}
          >
            <Row gutter={16}>
              <Col xl={{ span: 12, offset: 6 }} xs={{ span: 24 }}>
                <p className="paragraph_form">
                  <strong> {t("label_new_email")} </strong>
                </p>
                <Form.Item
                  name="newEmail"
                  type="email"
                  rules={[
                    { required: true, message: t("message_new_email") },
                    { type: "email", message: t("message_invalid_mail") },
                  ]}
                >
                  <Input placeholder={t("label_email")} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xl={{ span: 12, offset: 6 }} xs={{ span: 24 }}>
                <p className="paragraph_form">
                  <strong> {t("label_confirm_email")} </strong>
                </p>
                <Form.Item
                  name="confirmEmail"
                  type="email"
                  dependencies={["newEmail"]}
                  rules={[
                    { required: true, message: t("message_confirm_email") },
                    { type: "email", message: t("message_invalid_mail") },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newEmail") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          t("message_invalid_confirm_email")
                        );
                      },
                    }),
                  ]}
                >
                  <Input placeholder={t("label_confirm_email")} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xl={{ span: 12, offset: 6 }} xs={{ span: 24 }}>
                <p className="paragraph_form">
                  <strong> {t("label_password")} </strong>
                </p>
                <Form.Item
                  type="password"
                  name="password"
                  rules={[
                    { required: true, message: t("message_form_password") },
                  ]}
                >
                  <Input.Password placeholder={t("label_password")} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col xl={{ span: 4 }} xs={{ span: 10 }}>
                <BaseButton
                  className="shadow-button"
                  type="primary"
                  block
                  shape="round"
                  htmlType="submit"
                >
                  {t("label_save").toUpperCase()}
                </BaseButton>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
  );
};
export default ChangeEmail;
