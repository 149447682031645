import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

const useAnalytics = () => {
  const sendDataToGTM = useGTMDispatch();

  function objectHasValue(obj, value) {
    if (Object.values(obj).indexOf(value) === -1) {
      throw new Error(
        `Unknown value '${value}'. Valid values are ${Object.values(obj)}`
      );
    }
  }

  const ACTIONS = {
    COMPLETE: "complete",
    DELETE: "delete",
    UPDATE: "update",
    NEW: "new",
    PAUSE: "pause",
    READ: "read",
    REDO: "redo",
    SUBMIT: "submit",
  };

  const CONTENT_TYPES = {
    EBOOK: "eBook",
  };

  const EVENTS = {
    CONFIRM_CODE: "confirm_code",
    LOGIN: "login",
    PAGE_VIEW: "page_view",
    SELECT_CONTENT: "select_content",
    SIGNUP: "sign_up",
    SURVEY: "survey",
    USER: "user",
  };

  const METHODS = {
    EDUKAIROS: "edukairos",
    GOOGLE: "google.com",
  };

  const STATUS = {
    ERROR: "error",
    FAIL: "fail",
    SUCCESS: "success",
  };

  function logConfirmCode(action, status) {
    objectHasValue(ACTIONS, action);
    objectHasValue(STATUS, status);
    //return ReactGA.logEvent(EVENTS.CONFIRM_CODE, {
    //  action: ACTIONS.SUBMIT,
    //  status: status,
    //});
  }

  function logEbookView(id) {
    sendDataToGTM({
      event: EVENTS.SELECT_CONTENT,
      content_type: CONTENT_TYPES.EBOOK,
      item_id: id.toString(),
    });
  }

  function logLogin(method) {
    objectHasValue(METHODS, method);
    sendDataToGTM({ event: EVENTS.LOGIN, method: method });
  }

  function logSurvey(action, status, instanceId, questionId) {
    objectHasValue(ACTIONS, action);
    objectHasValue(STATUS, status);
    //return ReactGA.logEvent(EVENTS.SURVEY, {
    //  action: action,
    //  status: status,
    //  instance: instanceId,
    //  question: questionId,
    //});
  }

  function setUserId(id) {
    //return id ? ReactGA.setUserId(id) : null;
  }

  function logSignUp(method) {
    objectHasValue(METHODS, method);
    sendDataToGTM({ event: EVENTS.SIGNUP, method: method });
    //return ReactGA.logSignUp({ method: method });
  }

  function logTutorialBegin() {
    //return ReactGA.logTutorialBegin();
  }

  function logTutorialComplete() {
    //return ReactGA.logTutorialComplete();
  }

  function logUserDelete(email, status) {
    objectHasValue(STATUS, status);
    //return ReactGA.logEvent(EVENTS.USER, {
    //  action: ACTIONS.DELETE,
    //  status: status,
    //  email: email,
    //});
  }

  function logUserUpdate(status) {
    objectHasValue(STATUS, status);
    //return ReactGA.logEvent(EVENTS.USER, {
    //  action: ACTIONS.UPDATE,
    //  status: status,
    //});
  }

  function logPageView(location) {
    sendDataToGTM({ event: EVENTS.PAGE_VIEW, page_location: location });
  }

  return {
    ACTIONS,
    METHODS,
    STATUS,

    logConfirmCode,
    logEbookView,
    logLogin,
    logPageView,
    logSignUp,
    logSurvey,
    logTutorialBegin,
    logTutorialComplete,
    logUserDelete,
    logUserUpdate,
    setUserId,
  };
};
export default useAnalytics;
